<template>
  <div>
    <Navigation />
    <!-- AI作画 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>

        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“AI助手”-AI绘画" />
            <ai_switch :select_version="form.select_version" tab1_name="基础版" tab2_name="高级版"
              @select_versionShow="select_versionShow" />
            <ai_select selectTitle="分辨率" :select_list="resolving_power_List" @getSelectVal="get_resolving_power" />
            <!-- <div class="upLoad_text flex" v-if="form.select_version == '2'">
              <p class="upLoad_text">宽分辨率：</p>
              <el-select v-model="form.resolving_width" placeholder="选择分辨率">
                <el-option v-for="(item, index) in widthList" :key="index" :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="upLoad_text flex" v-if="form.select_version == '2'">
              <p class="upLoad_text">高分辨率：</p>
              <el-select v-model="form.resolving_height" placeholder="选择分辨率">
                <el-option v-for="(item, index) in heighthList" :key="index" :value="item">
                </el-option>
              </el-select>
            </div> -->
            <div class="form_ct flex">
              <p class="upLoad_text">绘画画风：</p>
              <!-- <el-select v-model="form.style" placeholder="请选择">
                <el-option v-for="(item, index) in styleList" :key="index" :value="item">
                </el-option>
              </el-select> -->
              <div class="upload_box">
                <el-input v-model="form.style" placeholder="如:卡通画、油画、山水画等。"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">主题描述：</p>
              <div class="upload_box">
                <el-input show-word-limit :maxlength="maxLength" resize="none" type="textarea" :rows="3"
                  :placeholder="placeHolder" v-model="form.input_text">
                </el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">细节描述：</p>
              <div class="upload_box">
                <el-input show-word-limit :maxlength="detail_maxlength" resize="none" type="textarea" :rows="3"
                  :placeholder="detail_placeHolder" v-model="form.detail_text">
                </el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">背景描述：</p>
              <div class="upload_box">
                <el-input show-word-limit maxlength="20" resize="none" type="textarea" :rows="3"
                  placeholder="如：黑色背景，水波纹，暖光渐变" v-model="form.bg_text">
                </el-input>
              </div>
            </div>
            <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
            <ai_img :type="1" :contentData="contentData" />
          </div>
          <aiNav />
          <aiNum title="计费规则：" :text="aiNumText" :visible="aiNumShow" @close="aiNumShow = false" @postData="postData" />
          <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
        </div>
      </div>
    </div>
    <footerBot />
  </div>
</template>

<script>
import aiNav from '@/components/aiNav.vue'
import ai_select from '@/components/ai_select.vue'
import ai_title from '@/components/ai_title.vue'
import ai_img from '@/components/ai_img'
import ai_switch from '@/components/ai_switch'
import ai_button_file_data from '@/components/ai_button_file_data'

export default {
  mounted() { this.getUserInfo() },
  components: {
    aiNav,
    ai_select,
    ai_title,
    ai_img,
    ai_switch,
    ai_button_file_data
  },
  data() {
    return {
      aiNumText: 'AI作图(基础版0.90元)',
      placeHolder: '主体描述一描述提醒:请描述预生成图片主体内容:字数限制在 30字内(如:人物，动物，场景等)',
      detail_placeHolder: '请描述预生成图片细节内容字数限制在 70字内(如:主体细节+背景描述等)',
      detail_maxlength: 70,
      maxLength: 30,
      form: {
        select_version: '1', //类型
        style: '', // 绘画画风
        resolving_power: '', //基础版分辨率
        input_text: '', // 主题描述
        detail_text: '', //详细描述
        bg_text: '', //背景描述
        // resolving_width: '',// 分辨率宽度
        // resolving_height: '',// 分辨率高度
      },
      // widthList: ['360', '512', '640', '720', '1024', '1280', '1440', '2048', '2560'],
      // heighthList: ['360', '512', '640', '720', '1024', '1280', '1440', '2048', '2560'],
      resolving_power_List: ['512*512', '640*360', '360*640', '1024*1024', '720*1280', '1280*720'],
      styleList: ['探索无限', '古风', '二次元', '浮世绘', 'low poly', '未来主义', '概念艺术', '赛博朋克', '洛丽塔风格', '巴洛克风格', '超现实主义', '水彩画', '蒸汽波艺术', '油画', '卡通画'],
      contentData: {}, //内容
      aiNumShow: false,
      aiPoupShow: false,
    }
  },
  methods: {
    get_resolving_power(val) {
      console.log(val);
      this.form.resolving_power = val
    },
    select_versionShow(id) {

      this.form.select_version = id
      this.form = {
        select_version: this.form.select_version,
        style: '', // 绘画画风
        resolving_power: '', //基础版分辨率
        input_text: '', // 主题描述
        detail_text: '', //详细描述
        bg_text: '', //背景描述
      }
      if (this.form.select_version == '1') {
        this.aiNumText = 'AI作图(基础版0.90元)'
        this.maxLength = 30
        this.detail_maxlength = 70
        this.placeHolder = `主题描述一描述提醒:请描述预生成图片主体内容:字数限制在 ${this.maxLength}字内(如:人物，动物，场景等)`
        this.detail_placeHolder = '请描述预生成图片细节内容字数限制在 70字内(如:主体细节+背景描述等)'
        this.resolving_power_List = ['512*512', '640*360', '360*640', '1024*1024', '720*1280', '1280*720']

      } else {
        this.aiNumText = 'AI作图(高级版3.00元)'
        this.maxLength = 50
        this.placeHolder = `主题描述一描述提醒:请描述预生成图片主体内容:字数限制在 ${this.maxLength}字内(如:人物，动物，场景等)`
        this.detail_maxlength = 120
        this.detail_placeHolder = '请描述预生成图片细节内容字数限制在 120字内(如:主体细节+背景描述等)'
        this.resolving_power_List = ['512*512', '640*360', '360*640', '1024*1024', '1280*720', '720*1280', '2048*2048', '2560*1440', '1440*2560', '3840*2160', '2160*3840']
      }
      console.log(this.form);
    },
    // 获取用户信息
    getUserInfo() {
      let $user_info = JSON.parse(localStorage.getItem('user_info'))
      if ($user_info) {
        this.curlGet('/api/user/info').then((res) => {
          if (res.data.code) {
            this.$user_info = res.data.data
            this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
          }
        })
      }
    },
    file_data() {
      if (this.$user_info.money <= 0) {
        this.aiPoupShow = true
      } else {
        this.aiNumShow = true
        // this.postData()
      }
    },
    postData() {
      this.aiNumShow = false
      this.$refs.ai_button_file_data.loading_show()
      let data = {}
      data.mobile = this.$user_info.mobile
      if (this.form.select_version == '1') {
        // 辣鸡版生成-发送数据
        data.resolving_power = this.form.resolving_power
      } else {
        // 高鸡版生成-发送数据
        data.resolving_width = this.form.resolving_power.substring(0, this.form.resolving_power.indexOf('*'))
        data.resolving_height = this.form.resolving_power.substring(this.form.resolving_power.indexOf('*') + 1, this.form.resolving_power.length + 1)
      }
      for (const i in this.form) {
        console.log('i', i);
        if (typeof this.form[i] == 'string' && this.form[i].trim() != '' && i != 'resolving_power') {
          data[i] = this.form[i]
        }
      }
      this.curlPost('/file/drawing_ai', data).then(res => {
        this.$refs.ai_button_file_data.loading_show()
        if (res.data.code) {
          if (res.data.data.output_content) {
            this.contentData = res.data.data
            // this.form = {
            //   select_version: this.form.select_version,
            //   style: '',
            //   detail_text: '',
            //   resolving_width: '',
            //   resolving_height: '',
            //   resolving_power: '',
            //   input_text: '',
            // }
            this.$alert('生成成功!该图片链接1小时时限期,过时图片链接失效', '提示:', {
              confirmButtonText: '确定',
              callback: action => {

              }
            });

            // 扣费
            this.curlPost('/api/users/money_log/spend', {
              ai_id: res.data.data.id,
              amount: res.data.data.money,
            }).then(res => {
              if (res.data.code) {
                this.getUserInfo()
              }
            })

          }
        } else {
          return this.$message({
            message: res.data.msg,
            type: 'warning',
            offset: 80,
          });
        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>